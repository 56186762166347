import useLocalStorageState from 'use-local-storage-state';

// :: Utils
import { getLocalStorage } from '../utils/localStorage';

const useToken = () => {
  const [user] = useLocalStorageState('cms.user');
  return user?.token;
};

export const getToken = () => {
  const userRaw = getLocalStorage('cms.user');
  const userDetails = JSON.parse(userRaw);
  return userDetails?.token;
};

export default useToken;
