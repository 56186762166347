import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { getTestProps } from '../../lib/helpers';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import Editor from '@toast-ui/editor';
// eslint-disable-next-line max-len
import codeSyntaxHighlightPlugin from '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight-all.js';
import colorPlugin from '@toast-ui/editor-plugin-color-syntax';
import './markdown.css';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import { useModals } from '../../contexts/ModalContext';
import LinkObjectContentModal from '../RelationField/LinkObjectContentModal/LinkObjectContentModal';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';
import FileButton from '../FileButton/FileButton';

const Markdown = ({
  name,
  value,
  onChange,
  onBlur,
  label,
  error,
  required,
  disabled,
  helpText,
  onMediaUpload,
  additionalClasses,
  testId,
}) => {
  const editor = useRef(null);
  const editorElement = useRef();
  const modal = useModals();
  const { t } = useTranslation();

  const handleDisabled = useCallback(() => {
    if (!editor.current) return;
    editor.current.mdEditor.el.inert = disabled;
    editor.current.wwEditor.el.inert = disabled;
  }, [disabled]);

  useEffect(() => handleDisabled(), [handleDisabled]);

  const handleChange = useCallback(() => {
    const currentValue = editor.current.getMarkdown();
    onChange(currentValue, name);
  }, [onChange, name]);

  const addMediaToEditor = useCallback(async () => {
    const media = await modal({
      title: (
        <div className="flex flex-wrap justify-between gap-1">
          <div className="text-3xl">{t('Global.MediaLibrary')}</div>
          <FileButton onUpload={onMediaUpload} multiple testId={testId} />
        </div>
      ),
      content: (
        <LinkObjectContentModal
          relationType="_media"
          onMediaUpload={onMediaUpload}
          returnContentObject
          isMultiple={
            process.env.REACT_APP_TEXT_EDITORS_MULTIPLE_MEDIA_MODAL.split(
              ',',
            ).join(',') === 'true'
          }
          {...getTestProps(testId, 'link-modal', 'testId')}
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'h-[calc(100vh-32px)]',
    });
    if (media && Object.keys(media).length) {
      Object.values(media).forEach((newMedia) => {
        const mediaUrl = getMediaUrl(newMedia);
        if (newMedia.type === 'image') {
          editor.current.exec('addImage', {
            altText: newMedia.fileName,
            imageUrl: mediaUrl,
          });
        } else {
          editor.current.exec('addLink', {
            linkText: newMedia.fileName,
            linkUrl: mediaUrl,
          });
        }
      });
    }
  }, [onMediaUpload, modal, t, testId]);

  const handleFullScreen = useCallback(() => {
    editorElement.current.classList.toggle('full-size-editor');
  }, [editorElement]);

  const handleEditorLoad = useCallback(() => {
    handleDisabled();

    editor.current.commandManager.addCommand('markdown', 'fullSize', () =>
      handleFullScreen(),
    );
    editor.current.commandManager.addCommand('wysywig', 'fullSize', () =>
      handleFullScreen(),
    );

    editor.current.commandManager.addCommand(
      'markdown',
      'addMediaToEditor',
      () => addMediaToEditor(),
    );
    editor.current.commandManager.addCommand(
      'wysywig',
      'addMediaToEditor',
      () => addMediaToEditor(),
    );
  }, [addMediaToEditor, handleDisabled, handleFullScreen]);

  useEffect(() => {
    if (editor.current) return;

    editor.current = new Editor({
      el: editorElement.current,
      height: '400px',
      usageStatistics: false,
      initialValue: value,
      events: { change: handleChange, blur: () => onBlur(name) },
      plugins: [codeSyntaxHighlightPlugin, colorPlugin],
      initialEditType: 'markdown',
      previewStyle: 'tab',
      autofocus: false,
      toolbarItems: [
        [
          {
            className: 'toastui-editor-strech-button',
            name: 'full-screen',
            tooltip: 'Full screen',
            command: 'fullSize',
          },
        ],
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task', 'indent', 'outdent'],
        [
          'table',
          'image',
          {
            className: 'toastui-editor-media-button',
            name: 'add-media-from-library',
            tooltip: 'Select media from media library',
            command: 'addMediaToEditor',
          },
          'link',
        ],
        ['code', 'codeblock'],
      ],
    });

    handleEditorLoad();
  }, [
    editorElement,
    value,
    disabled,
    handleChange,
    onBlur,
    handleDisabled,
    handleFullScreen,
    addMediaToEditor,
    handleEditorLoad,
    name,
  ]);

  useEffect(() => {
    return () => {
      if (!editor.current) return;
      if (editor.current.destroy) {
        editor.current.destroy();
        editor.current = null;
      }
    };
  }, []);

  return (
    <div
      className={twMerge(
        'w-full',
        'flex',
        'flex-col',
        'relative',
        additionalClasses,
      )}
    >
      <label
        className="text-sm text-slate-400 dark:text-gray-200 mb-1"
        {...getTestProps(testId, 'label')}
      >
        {label}
        {required && <RequiredTemplate />}
      </label>
      <div
        ref={editorElement}
        className={twMerge(
          'border rounded-lg',
          error ? 'border-red' : 'border-slate-200',
          disabled ? 'bg-gray disabled-editor' : 'bg-white',
        )}
        {...getTestProps(testId, 'editor')}
      />
      <HelpErrorTextsTemplate helpText={helpText} error={error} />
    </div>
  );
};

export default Markdown;

Markdown.propTypes = {
  /**
   * Field form name
   */
  name: PropTypes.string,
  /**
   * Markdown value
   */
  value: PropTypes.string,
  /**
   * On chanage handler
   */
  onChange: PropTypes.func,
  /**
   * On blur handler
   */
  onBlur: PropTypes.func,
  /**
   * Label above the editor
   */
  label: PropTypes.string,
  /**
   * Error to display under the editor
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * If value is required
   */
  required: PropTypes.bool,
  /**
   * If the editor is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Help text to display under the editor
   */
  helpText: PropTypes.string,
  /**
   * On media upload callback
   */
  onMediaUpload: PropTypes.func,
  /**
   * Additional classes for editor
   */
  additionalClasses: PropTypes.string,
  /**
   * Markdown test id
   */
  testId: PropTypes.string,
};

Markdown.defaultProps = {
  value: '',
  onChange: /* istanbul ignore next */ () => null,
  onBlur: /* istanbul ignore next */ () => null,
  label: '',
  error: null,
  required: false,
  disabled: false,
  helpText: '',
  onMediaUpload: /* istanbul ignore next */ () => null,
  additionalClasses: '',
  testId: '',
};
