import i18n from '../../i18n';
import { FlotiqScopedApiClient } from './flotiq-scoped-api-client';
import { PluginEventHandler } from './plugin-event-handler';
import { Jodit } from 'jodit-pro/es2015/jodit.min.js';
import { toast } from 'react-hot-toast';
import { getModal } from '../../contexts/ModalContext';
import SchemaModalContent from '../../form/SchemaModalContent/SchemaModalContent';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';

const callbackRegister = {
  'flotiq.grid::add': {},
  'flotiq.grid.cell::render': {},
  'flotiq.grid.filter::render': {},
  'flotiq.form.field::config': {},
  'flotiq.form.field::render': {},
  'flotiq.form.sidebar-panel::add': {},
  'flotiq.form::add': {},
  'flotiq.form::after-submit': {},
  'flotiq.plugins.manage::render': {},
  'flotiq.plugins.manage::form-schema': {},
  'flotiq.plugin.settings::changed': {},
  'flotiq.plugin::removed': {},
  'flotiq.media.crop::config': {},
};

const pluginSettingsRegister = {};

const errorsRegister = {};

const registeredPluginHandlers = {};
const loadedPluginScripts = {};

const systemPluginsIds = JSON.parse(
  process.env.REACT_APP_SYSTEM_PLUGINS || '[]',
).map(({ id }) => id);

/**
 * Main class for handling Flotiq plugins. It is responsible for registering, running and managing plugins.
 * A global instance of this class is available as `window.FlotiqPlugins`.
 *
 * @memberof FlotiqPlugins.Core
 */
class FlotiqPluginsRegistry {
  spaceId = null;

  openModal = async (config) => {
    const { id, buttons, title, content, size, hideClose } = config;
    const contentElement = <ElementFromPlugin results={[content]} />;

    const modal = getModal();
    return modal({
      id,
      title,
      buttons,
      content: contentElement,
      size,
      hideClose,
    });
  };

  openSchemaModal = async (config) => {
    return this.openModal({
      ...config,
      buttons: [],
      content: <SchemaModalContent key="form" form={config.form} />,
    });
  };

  enabled = () =>
    process.env.REACT_APP_ENABLE_UI_PLUGINS?.split(',').join(',') === 'true';

  getCurrentLanguage = () => i18n.language;

  getLoadedPlugins = () =>
    Object.fromEntries(
      Object.entries(registeredPluginHandlers).filter(
        ([id]) => !systemPluginsIds.includes(id),
      ),
    );

  getPluginSettings = (id) => pluginSettingsRegister[id];
  setPluginSettings = (id, newSettings) => {
    pluginSettingsRegister[id] = newSettings;
  };

  runPluginCode(code) {
    if (!FlotiqPlugins.enabled()) {
      console.warn('Plugins are disabled, skipping plugin code execution');
      return;
    }
    const pluginScript = `
    with (this) {
      ${code}
    }
    `;

    /**
     * This `new Function` is considered safe-ish as it is executing external plugin code by design.
     */
    // eslint-disable-next-line no-new-func
    new Function(pluginScript).call(window);
  }

  getApiUrl = () => process.env.REACT_APP_FLOTIQ_API_URL;

  closeModal = (id, result) => getModal().close(id, result);

  async loadPlugin(id, url, settings) {
    if (!FlotiqPlugins.enabled()) {
      console.warn('Plugins are disabled, skipping plugin loading', id, url);
      return;
    }
    try {
      const scriptText = await fetch(url).then((r) => r.text());
      FlotiqPlugins.runPluginCode(scriptText);
      loadedPluginScripts[id] = url;

      pluginSettingsRegister[id] = settings;
    } catch (e) {
      errorsRegister[id] = [
        ...(errorsRegister[id] || []),
        'Error while loading plugin script',
      ];
      console.error('Error while loading plugin script', e);
    }
  }

  /**
   * Register new plugin. If plugin with given ID already exists, it will be unregistered first.
   *
   * @param {PluginInfo} pluginInfo
   * @param {PluginRegistrationCallback} callback
   */
  add(pluginInfo, callback) {
    if (!FlotiqPlugins.enabled()) {
      console.warn(
        'Plugins are disabled, skipping plugin registration',
        pluginInfo?.id,
      );
      return;
    }
    const { id, permissions } = pluginInfo;
    if (registeredPluginHandlers[id]) {
      registeredPluginHandlers[id].unregister();
    }
    // event handler for the plugin
    const eventHandler = new PluginEventHandler(
      pluginInfo,
      FlotiqPlugins,
      callbackRegister,
      registeredPluginHandlers,
    );

    registeredPluginHandlers[id] = eventHandler;

    const client = new Proxy(
      new FlotiqScopedApiClient(permissions, this.queryClient),
      {
        get: (target, prop) => {
          if (prop === 'getMediaUrl') return target.getMediaUrl.bind(target);
          if (prop === 'getContentTypes')
            return target.getContentTypes.bind(target);
          return {
            get: (id) => target.getObject(prop, id),
            getVersions: (id) => target.getObjectVersions(prop, id),
            getVersion: (id, version) =>
              target.getObjectVersion(prop, id, version),
            list: (params) => target.listObjects(prop, params),
            post: (object) => target.postObject(prop, object),
            put: (id, object) => target.putObject(prop, id, object),
            patch: (id, object) => target.patchObject(prop, id, object),
            delete: (id) => target.deleteObject(prop, id),
            getContentType: () => target.getContentType(prop),
            putContentType: (object) => target.putContentType(prop, object),
          };
        },
      },
    );

    callback(eventHandler, client, {
      Jodit,
      toast,
      getLanguage: this.getCurrentLanguage,
      openModal: this.openModal,
      openSchemaModal: this.openSchemaModal,
      getPluginSettings: () => this.getPluginSettings(id),
      getApiUrl: () => this.getApiUrl(),
      closeModal: (id, result = null) => this.closeModal(id, result),
      getSpaceId: () => this.spaceId,
    });

    if (!systemPluginsIds.includes(id))
      console.info('Plugin registered ⚡', pluginInfo);
  }

  /**
   * Unregister plugin event handlers by id
   * @param {string} pluginId The id of the plugin that should be unregistered from the plugin list
   */
  unregister(pluginId) {
    if (!pluginId) {
      throw Error('Plugin id was not provided');
    }

    if (registeredPluginHandlers[pluginId]) {
      registeredPluginHandlers[pluginId].unregister();
    }
  }

  /**
   * Unregister plugin event handlers by id
   * @param {string} pluginId The id of the plugin that should be unregistered from the plugin list
   */
  unregisterAllPlugins() {
    Object.keys(registeredPluginHandlers).forEach((pluginId) => {
      this.unregister(pluginId);
    });
  }

  #getResults(eventCallbacks, event, ...params) {
    return eventCallbacks.reduce((results, [pluginId, pluginCallbacks]) => {
      results.push(
        ...pluginCallbacks.map((c) => {
          try {
            return c(...params);
          } catch (e) {
            console.error(
              'Error while running plugin callback',
              pluginId,
              event,
              e,
            );
            errorsRegister[pluginId] = [
              ...(errorsRegister[pluginId] || []),
              `Error while running plugin callback ${event}.`,
            ];
            return null;
          }
        }),
      );
      return results;
    }, []);
  }

  /**
   * Execute all handlers for an event. Event name and parameters must be paired correctly.
   *
   * @param {string} event An event name to run. All registered handlers of this event will be executed.
   * @param  {...any} params Parameters to pass to the handlers.
   * @returns {Array} Array of results from all handlers
   */
  run(event, ...params) {
    if (!callbackRegister[event]) return [];
    return this.#getResults(
      Object.entries(callbackRegister[event]),
      event,
      ...params,
    );
  }

  /**
   * Execute all handlers for an event for the given plugin. Event name and parameters must be paired correctly.
   *
   * @param {string} event An event name to run. Only specified plugin handlers of this event will be executed.
   * @param {string} pluginId Plugin id to run the event for
   * @param  {...any} params Parameters to pass to the handlers.
   * @returns {Array} Array of results from all handlers of given plugin
   */
  runScoped(event, pluginId, ...params) {
    if (!callbackRegister[event] || !pluginId) return [];

    return this.#getResults(
      Object.entries(callbackRegister[event]).filter(([id]) => id === pluginId),
      event,
      ...params,
    );
  }

  hasPluginOneOfEvents(pluginId, events) {
    if (!events?.length || !pluginId) return false;

    for (const event of events) {
      const pluginEvent = Object.keys(callbackRegister[event] || {}).find(
        (id) => id === pluginId,
      );
      if (pluginEvent) return true;
    }

    return false;
  }

  init() {
    if (window.initFlotiqPlugins) {
      window.initFlotiqPlugins.forEach(({ pluginInfo, callback }) => {
        window.FlotiqPlugins.add(pluginInfo, callback);
      });
      window.initFlotiqPlugins.length = 0;
    }
  }

  getLoadedPluginsIds() {
    return Object.keys(registeredPluginHandlers).filter(
      (id) => !systemPluginsIds.includes(id),
    );
  }

  getPluginErrors(pluginId) {
    return Object.values(errorsRegister[pluginId] || {});
  }
}

const FlotiqPlugins = new FlotiqPluginsRegistry();
window.FlotiqPlugins = FlotiqPlugins;

export default FlotiqPlugins;

/**
 * @typedef {Object} PluginPermission
 * @memberof FlotiqPlugins.Core.PluginInfo
 *
 * @property {('CO'|'CTD')} type - permission type. Can be either 'CO' or 'CTD'
 * @property {boolean} canRead - whether plugin can read content of given type
 * @property {boolean} canWrite - whether plugin can write content of given type
 * @property {boolean} canCreate - whether plugin can create content of given type
 * @property {boolean} canDelete - whether plugin can delete content of given type
 * @property {string} ctdName - Name of content type permission applies to. '*' means all content types
 */

/**
 * A set of information required from plugin to register in Flotiq UI.
 * It also represents the structure of the `plugin-manifest.json` file, which is used
 * to add plugin permanently to the account, or to the Flotiq UI Plugins Registry (coming soon).
 *
 * @typedef {Object} PluginInfo
 * @memberof FlotiqPlugins.Core
 *
 * @property {string} id - unique plugin id. This information is requried both during development
 *           and in `plugin-manifest.json`
 * @property {string} name - plugin display name. This information is requried both during development
 *           and in `plugin-manifest.json`
 * @property {string} version - plugin version. This information is requried in `plugin-manifest.json`.
 *           It can be omitted when registering plugin with `FlotiqPlugins.add` during development.
 * @property {string} url - plugin version. This information is requried in `plugin-manifest.json`.
 *           It can be omitted when registering plugin with `FlotiqPlugins.add` during development.
 * @property {string} [description] - plugin description
 * @property {string} [repository] - url to source code repository
 * @property {PluginPermission[]} [permissions] - list of permissions that plugin requires
 */

/**
 * Api client
 *
 * @typedef {Object} FlotiqApiClient
 * @memberof FlotiqPlugins.Core
 *
 * @property {ContentTypeAPIClient<contenttypename>} contenttypename - api client for given content type
 * @property {function} contenttypename.get - get a content object, passing id as an argument.
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.getVersions - get a content object versions, passing id as an argument.
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.getVersion - get a content object version, passing id and version as arguments.
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.list - list content objects, passing query params as an first argument
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.post - create content object, passing object as an argument
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.put - update content object, passing id and object as arguments
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.delete - delete content object, passing id as an argument
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.getContentType - get definition for given content type
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.patch - partialy update content object, passing id and object as arguments
 *     Will throw an error without access to the content type.
 * @property {function} contenttypename.putContentType - partialy update content type, passing object as argument
 *     Will throw an error without access to the content type.
 */

/**
 * Generate url for media. Use width/height params to generate url for resized image
 *
 * @method
 * @name FlotiqPlugins.Core.FlotiqApiClient#getMediaUrl
 * @param {object} mediaContentObject
 * @param {number} width
 * @param {number} height
 * @returns {string} media url
 */

/**
 * Generate url for media. Use width/height params to generate url for resized image
 *
 * @method
 * @name FlotiqPlugins.Core.FlotiqApiClient#getContentTypes
 * @param {object} params query parameters for content types list (e.g. limit, page etc)
 * @returns {Promise<ContentTypesResponse>} Api response containing list of content types
 */

/**
 * @typedef {import('jodit-pro').Jodit} Jodit
 */

/**
 * Function that will be called on formik validate. If there will be no result,
 * there will be yup validation based on schema. If returns errors, they will be passed
 * to formik.
 *
 * @callback onValidate
 * @memberof FlotiqPlugins.Form
 * @param {object} values Current setttings
 * @returns {null|object} Object or null if there are no errors
 */

/**
 * Function that will be called on formik submit. Should return array with new settings data
 * and errors
 *
 * @callback onSubmit
 * @memberof FlotiqPlugins.Form
 * @param {object} values Submitted setttings
 * @returns {Array}
 *          Result that has contains 2-element array:
 *          `[settings: object, errors: object]` with new settings and errors if occured during submission.
 */

/**
 * Modal button config.
 *
 * @typedef {object} FlotiqModalButton
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @property {string} key - Unique button key
 * @property {string} label - Button label
 * @property {('blue' | 'blueBordered' | 'borderless' | 'gray' | 'grayBordered' | 'redBordered')} color -
 *            Predefined button colors
 * @property {any} result - Result that will be returned from the modal promise on the button click.
 *            You can return any data type, including objects and arrays.
 * @property {func} onClick -
 *            Whenever you want to perform some action on button click that is e.g. waiting for some results,
 *            you can use buttons `onClick` property. If the onClick method will return some result, the modal
 *            will be resolved with this value, otherwise undefined will be the result.
 */

/**
 * Modal properties to generate modal.
 *
 * @typedef {object} FlotiqModalConfig
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @property {string} title - Modal title
 * @property {null|string|array|number|boolean|HTMLElement|ReactElement} content - Modal content
 * @property {FlotiqModalButton[]} buttons - Modal buttons
 * @property {('sm'|'md'|'lg'|'xl'|'2xl')} size - Modal predefined size
 * @property {boolean} hideClose - If close icon should be hidden in the modal.
 *            Closing modal will be avaiable only on modal buttons click.
 */

/**
 * Function that will open the modal With an HTML content managed by the plugin.
 *
 *
 * @callback openModal
 * @see {@link .docs/public/plugin-examples.md#open-custom-modal|Open custom modal} section in our examples.
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @param {FlotiqModalConfig} config Modal properties
 * @returns {Promise<any>}
 *          Our internal modal system is based on promises. You'll get the modal promise upon creating the modal
 *          and it will be resolved (or rejected) automatically once result button is clicked.
 *          Result can be provided either by using the `result` field of each button,
 *          or by returning it from `onClick` method of the button.
 */

/**
 * Form properties to generate modal with form.
 *
 * @typedef {object} FlotiqModalFormConfig
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @property {object} schema - Schema object compatible flotiq api.
 All passed schema gets nonCtdSchema value set on true.
 * @property {object} options Additional options passed to settings form
 * @property {FlotiqPlugins.Form.onValidate} options.onValidate Custom validate on form data change
 * @property {FlotiqPlugins.Form.onSubmit} options.onSubmit Custom submit handler.
 *            If not provided on form submission, the modal will be resolved with form values.
 * @property {boolean} options.disbaledBuildInValidation If build in validation should be disabled.
 *            If not provided on form submission, the validation has to be handled by onValidate option.
 * @property {object} initialData - Initial data for form
 * @property {object} labels - Custom labels for modal buttons
 * @property {string} labels.ok - Custom label for submit button, default: "Ok"
 * @property {string} labels.cancel - Custom label for cancel button, default: "Cancel"
 */

/**
 * Modal properties with form data to generate modal with form.
 *
 * @typedef {object} FlotiqSchemaModalConfig
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @property {string} title - Modal title
 * @property {('sm'|'md'|'lg'|'xl'|'2xl')} size - Modal predefined size
 * @property {boolean} hideClose - If close icon should be hidden in the modal.
 *            Closing modal will be avaiable only on modal buttons click.
 * @property {FlotiqModalFormConfig} form Form config
 *
 */

/**
 * Function that will open the modal with a form based on the Content Type Definition schema.
 *
 * @callback openSchemaModal
 * @see {@link .docs/public/plugin-examples.md#form-defined-with-a-schema-that-returns-data CTD form modal}
 *      and {@link .docs/public/plugin-examples.md#form-with-custom-submit-and-button-labels customized CTD form modal}
 *      in our examples.
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @param {FlotiqSchemaModalConfig} config Modal properties
 * @returns {Promise<any>}
 *          Our internal modal system is based on promises. You'll get the modal promise upon creating the modal
 *          and it will be resolved (or rejected) automatically once result button is clicked.
 *          Result can be provided either by using the `result` field of each button,
 *          or by returning it from `onClick` method of the button.
 */

/**
 * Function that will return the plugin setting.
 *
 * @callback getPluginSettings
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @returns {string} Plugin settings returned as string.
 */

/**
 *  Function that will return the Flotiq API URL.
 *
 * @callback getApiUrl
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @returns {string} Flotiq API URL
 */

/**
 * Function that will close modal with given id with given result
 *
 * @callback closeModal
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 */

/**
 * Function that will return the currently entered space ID
 *
 * @callback getSpaceId
 * @memberof FlotiqPlugins.Core.FlotiqGlobals
 * @returns {string}
 */

/**
 * @typedef {Object} FlotiqGlobals
 * @memberof FlotiqPlugins.Core
 *
 * @property {Jodit} Jodit - Jodit editor instance
 * @property {toast} toast - Toast ui instance
 * @property {openModal} openModal - Function to open modal
 * @property {openSchemaModal} openSchemaModal - Function to open modal with form based on schema passed with config
 * @property {getPluginSettings} getPluginSettings - Function to get settings for the plugin
 * @property {getApiUrl} getApiUrl - Function to get flotiq api url
 * @property {closeModal} closeModal - Function to close modal with given id with given result
 * @property {getSpaceId} getSpaceId - Function to get the currently entered space ID
 */

/**
 * A callback function that will be called when plugin is registered and started.
 * This callback should register all event handlers for the plugin.
 *
 * @callback PluginRegistrationCallback
 * @memberof FlotiqPlugins.Core.FlotiqPluginsRegistry
 * @param {PluginEventHandler} eventHandler - event handler for the plugin
 * @param {FlotiqPlugins.Core.FlotiqApiClient} client - api client for the plugin
 * @param {FlotiqGlobals} globals - global variables available for the plugin
 */
